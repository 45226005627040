import React from 'react'
import { Price } from '../components'

export default function Home() {
    return (
        <>
            <section className="bg-box dark:bg-box_dark rounded-lg border border-box_border dark:border-box_border_dark">
                <div className="container px-6 py-16 mx-auto">
                    <div className="items-center lg:flex">
                        <div className="w-full lg:w-1/2">
                            <div className="lg:max-w-lg">
                                <h1 className="text-3xl font-semibold text-gray-800 dark:text-white lg:text-4xl">Best place to take <br/> your <span className="text-primary">money control</span></h1>
                                
                                <p class="my-5 text-gray-600 dark:text-gray-400"></p>
                                
                                <a href='https://app.moneytrack.es' class="w-full px-5 py-2 mt-6 text-sm tracking-wider text-white uppercase transition-colors duration-300 transform bg-primary rounded-lg lg:w-auto hover:bg-primary focus:outline-none focus:bg-primary">Login</a>
                            </div>
                        </div>

                        <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
                            <img class="w-full h-full lg:max-w-3xl" src="https://api.moneytrack.es/cdn/web/foto1.png" alt="Imagen" />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="container px-6 py-16 mx-auto">
                    <h2 className='text-primary text-3xl text-center font-bold'>Privacidad y seguridad.</h2>
                    <h3 className='text-black dark:text-white text-2xl text-center font-bold'>De serie en todo lo que hacemos.</h3>
                </div>
                <div className="flex gap-10">
                    <div className="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0 w-full">
                        <div className="border rounded-lg md:mx-4 border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark w-full">
                            <div className="p-6">
                                <h1 className="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white">Autenticación Con Email</h1>

                                <p className="mt-4 text-gray-500 dark:text-gray-300">Accede a tu cuenta con email y contraseña</p>

                                <img className='h-[150px] w-[104px] mx-auto mt-[80px] mb-[60px]' src='https://api.moneytrack.es/cdn/icons/lock.svg' />

                                <h2 className="mt-4 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300"><span className="text-base font-medium">En app iOs y Navegador</span></h2>
                            </div>


                            {/*
                                <hr className="border-box_border dark:border-box_border_dark" />

                                <div className="p-6">
                                    <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">What’s included:</h1>
                                </div>
                            */}

                        </div>
                    </div>
                    <div className="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0 w-full">
                        <div className="border rounded-lg md:mx-4 border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark w-full">
                            <div className="p-6">
                                <h1 className="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white">Autenticación Biometrica</h1>

                                <p className="mt-4 text-gray-500 dark:text-gray-300">Accede a tu cuenda usando tu cara o tu dedo</p>

                                <div className='flex'>
                                    <img className='size-[150px] mx-auto mt-[80px] mb-[60px]' src='https://api.moneytrack.es/cdn/icons/faceid.svg' />
                                    <img className='size-[150px] mx-auto mt-[80px] mb-[60px]' src='https://api.moneytrack.es/cdn/icons/touchid.svg' />
                                </div>

                                <h2 className="mt-4 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300"><span className="text-base font-medium">Solo en app iOs</span></h2>

                            </div>
                            {/*

                                <hr className="border-box_border dark:border-box_border_dark" />

                                <div className="p-6">
                                    <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">What’s included:</h1>
                                </div>

                            */}
                        </div>
                    </div>
                </div>
            </section>

            <section className='pb-16'>
                <div class="container px-6 py-16 mx-auto">
                    <h2 className='text-primary text-3xl text-center font-bold'>Donde Quieras.</h2>
                    <h3 className='text-black dark:text-white text-2xl text-center font-bold'>Cuando Quieras.</h3>
                </div>
                <div className="flex gap-10">
                    <div className="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0 w-full">
                        <div className="border rounded-lg md:mx-4 border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark w-full">
                            <div className="p-6">
                                <h1 className="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white">Accede desde donde quieras</h1>

                                <p className="mt-4 text-gray-500 dark:text-gray-300">Accede desde donde quieras gracias a nuestros servidores</p>

                                <img className='w-[170px] mx-auto mt-[80px] mb-[60px]' src='https://api.moneytrack.es/cdn/icons/server.rack.svg' />

                                <h2 className="mt-4 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300"><span className="text-base font-medium"></span></h2>

                            </div>
                            {/*

                            <hr className="border-box_border dark:border-box_border_dark" />

                            <div className="p-6">
                                <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">What’s included:</h1>
                            </div>

                            */}
                        </div>
                    </div>
                    <div className="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0 w-full">
                        <div className="border rounded-lg md:mx-4 border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark w-full h-full">
                            <div className="p-6">
                                <h1 className="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white">Con mas de 175 divisas</h1>

                                <p className="mt-4 text-gray-500 dark:text-gray-300">Todo tipo de divisas</p>

                                <div className='flex gap-3'>
                                    <img className='size-[80px] mx-auto mt-[80px] mb-[60px]' src='https://api.moneytrack.es/cdn/icons/norwegiankronesign.circle.svg' />
                                    <img className='size-[80px] mx-auto mt-[80px] mb-[60px]' src='https://api.moneytrack.es/cdn/icons/eurosign.circle.svg' />
                                    <img className='size-[80px] mx-auto mt-[80px] mb-[60px]' src='https://api.moneytrack.es/cdn/icons/dollarsign.circle.svg' />
                                </div>

                                <h2 className="mt-4 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300"><span className="text-base font-medium"></span></h2>

                            </div>
                            {/*
                            <hr className="border-box_border dark:border-box_border_dark" />

                            <div className="p-6">
                                <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">What’s included:</h1>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </section>

           <section>
                <div className="container px-6 mx-auto">
                    <h2 className='text-primary text-3xl text-center font-bold'>Planes de pago.</h2>
                    <h3 className='text-black dark:text-white text-2xl text-center font-bold'></h3>
                </div>
                <Price />
           </section>
        </>
    )
}
