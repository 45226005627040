import React from 'react'

import dispositivos from '../data/dispositivosCompatibles.json'

import { HomePageIMG } from '../assets'

export default function Ios() {
    return (
        <>
            <section>
                <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                    <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg hover:scale-105"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg hover:scale-105"></div>
                    <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg hover:scale-105"></div>
                    <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800 object-cover relative">
                        <div style={{ "backgroundImage": "url(" + HomePageIMG + ")" }} className='w-[272px] h-[572px] mt-[2px] bg-no-repeat bg-cover bg-center'></div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container px-6 py-8 mx-auto">
                    <div className="xl:items-center xl:-mx-8 xl:flex">
                        <div className="flex flex-col items-center xl:items-start xl:mx-8 transition-all group">
                            <h1 className="text-2xl font-medium text-gray-800 capitalize lg:text-3xl dark:text-white">Dispositivos Compatibles</h1>

                            <div className="transition-all flex w-full mt-4">
                                <span className="inline-block w-40 transition-all group-hover:w-full h-1 bg-primary rounded-full"></span>
                                <span className="inline-block w-3 group-hover:hidden h-1 mx-1 bg-primary rounded-full"></span>
                                <span className="inline-block w-1 group-hover:hidden h-1 bg-primary rounded-full"></span>
                            </div>

                            <p className="mt-4 font-medium text-gray-500 dark:text-gray-300">
                                Todos los dispositivos con iOs 17.0 o superior
                            </p>

                            {/*
                            <a href="#" className="flex items-center mt-4 -mx-1 text-sm text-gray-700 capitalize dark:text-primary hover:underline hover:text-primary dark:hover:text-primary">
                                <span className="mx-1">read more</span>
                                <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </a>
                            */}
                        </div>

                        <div className="flex-1 xl:mx-8">
                            <div className="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0">
                                {
                                    dispositivos.map((item, index) => (

                                        <div className="w-full border rounded-lg md:mx-4 border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark">
                                            <div className="p-6">
                                                <h1 className="text-xl font-medium text-gray-700 lg:text-2xl dark:text-white">{item.name}</h1>

                                                <p className="mt-4 text-gray-500 dark:text-gray-300">{item.description}</p>

                                                <button className="transition-all w-full px-4 py-2 mt-6 tracking-wide text-white capitalize duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none hover:scale-105 ">
                                                    Empezar ahora
                                                </button>
                                            </div>

                                            <hr className="border-box_border dark:border-box_border_dark" />

                                            <div className="p-6">
                                                <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">Dipositivos</h1>

                                                <div className="mt-8 space-y-4">

                                                    {
                                                        item.dispositivos.map((device, index) => {

                                                            if (device.include == true) {
                                                                return (
                                                                    <div className="flex items-center">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-primary" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <span className="mx-4 text-gray-700 dark:text-gray-300">{device.name}</span>
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="flex items-center">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                                                                        </svg>

                                                                        <span className="mx-4 text-gray-700 dark:text-gray-300">{device.name}</span>
                                                                    </div>
                                                                )
                                                            }

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
