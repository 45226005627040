import React, { useState } from 'react'
import { Link } from 'react-router-dom';


const navItems = [
    {
        name: 'Inicio', 
        path: ''
    },
    {
        name: 'Precios', 
        path: '/price'
    },
    {
        name: 'iOs', 
        path: '/Ios'
    },
    {
        name: 'Navegador', 
        path: '/navegador'
    }
]

export default function Header({viewSelect, setView}) {

    const [verMenuMovil, setVerMenuMovil] = useState(false) 

    return (
        <>
        <nav className="bg-box dark:bg-box_dark sticky top-0 z-50 font border-b border-box_border dark:border-box_border_dark no-print">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                    <div className="flex w-full">
                        <div className="hidden lg:flex flex-shrink-0 items-center">
                            <Link to="/home" className='h-full flex'>
                                <div className="flex flex-shrink-0 items-center">
                                    <img className='w-[35px] h-[35px]' src='https://developer-moneytrack-es.vercel.app/favicon.ico' />
                                </div>
                            </Link>
                            <p className="pl-3 hidden lg:block"></p>
                        </div>
                        <div className="hidden w-full lg:-my-px lg:ml-6 lg:flex lg:space-x-8">
                            {
                                navItems.map((e, index) => {
                                    return (
                                        <Link to={e.path} key={index} className={viewSelect == e.name ? "border-slate-500 text-gray-900 dark:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium" : "border-transparent text-gray-500 hover:text-gray-700 dark:hover:text-white hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"}>{e.name}</Link>
                                    )
                                })
                            }
                            <div className="w-full flex justify-end items-center">
                                <span></span>
                            </div>
                        </div>
                        {/* Mobile Menu Header */}
                        <div className="flex justify-end s-my-px ml-6 lg:hidden space-x-8 w-full">
                            {
                                verMenuMovil ?
                                    <p onClick={() => setVerMenuMovil(verMenuMovil == true ? false : true)} className="cursor-pointer text-gray-900 border-transparent hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" className='w-[20px] h-[20px]' xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></p>
                                :
                                    <p onClick={() => setVerMenuMovil(verMenuMovil == true ? false : true)} className="cursor-pointer text-gray-900 border-transparent hover:border-gray-300 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="block h-6 w-6"><path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path></svg></p>
                            }
                            <div className="flex justify-center content-center items-center w-full">
                                <img src="https://developer-moneytrack-es.vercel.app/favicon.ico" alt='Logo MoneyTrack' className='h-[45px] py-2' />
                            </div>
                            <span></span>
                        </div>

                    </div>
                </div>
            </div>
            {/* Mobile Menu*/}
            <div className={verMenuMovil == true ? "lg:hidden" : "hidden"} id="headlessui-disclosure-panel-:R4ba:" data-headlessui-state="open">
            <div className="space-y-1 pt-2 pb-3">
                {
                    navItems.map((e, index) => {
                        return (
                            <Link to={e.path} key={index} onClick={() => {
                                setVerMenuMovil(verMenuMovil == true ? false : true)
                            }} className={viewSelect == e.name ? "bg-slate-50 dark:bg-[#121212] dark:text-white border-slate-500 text-slate-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium" : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"}>{e.name}</Link>
                        )
                    })
                }
            </div>
            </div>
        </nav>
        {/*
        <nav className='bg-box flex h-[175px] text-[#F5F5F5] z-50'>
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex'>
                <LogoVecZabala className='w-[104px] h-[119px]' />
                <div className='flex justify-between items-center w-full h-full'>
                    {
                        navItems.map(e => {
                            return (
                                <Link to={e.path} key={e.name} className='text-[#F5F5F5] hover:underline'>{t('nav_'+e.name)}</Link>
                            )
                        })
                    }
                </div>
                <LanguageSelector />
            </div>
        </nav>
        */}
        </>
    )
}