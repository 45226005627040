import React from 'react'

export default function PageNotFound() {
  return (
    <section className="bg-body dark:bg-body_dark h-[500px]">
      <div className="container flex items-center h-full px-6 py-12 mx-auto">
        <div>
          <p className="text-sm font-medium text-blue-500 dark:text-blue-400">404 error</p>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">No hemos podido encontrar la pagina</h1>
          <p className="mt-4 text-gray-500 dark:text-gray-400">Lo sentimos, la pagina que estas buscando no existe</p>
        </div>
      </div>
    </section>
  )
}
