import React, { useEffect, useState } from 'react'
import { createClient } from "@libsql/client";

import price from '../data/price.json'

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
  } from '@tremor/react';


export default function Price() {

    const [data, setData] = useState({columns: [], rows: []})

    const client = createClient({
        url: "libsql://moneytrack-pabloeguilazperez.turso.io",
        authToken: process.env.REACT_APP_TURSO_AUTH_TOKEN,
    });

    useEffect(() => {

        async function getData() {
           
           client.execute("SELECT * FROM LimitesUso").then((result) => {
               setData(result)
           });
           
        }

       getData();

    }, [])

    return (
        <>
            <section className="">
                <div className="container px-6 py-8 mx-auto">
                    <div className="xl:items-center xl:-mx-8 xl:flex">
                        <div className="flex-1 xl:mx-8">
                            <div className="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0">
                            {
                                price.map((item, index) => (

                                    <div className="max-w-sm mx-auto border rounded-lg md:mx-4 border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark">
                                        <div className="p-6">
                                            <h1 className="text-xl font-medium text-gray-700 capitalize lg:text-2xl dark:text-white">{item.name}</h1>

                                            <p className="mt-4 text-gray-500 dark:text-gray-300">{item.description}</p>

                                            <h2 className="mt-4 text-2xl font-semibold text-gray-700 sm:text-3xl dark:text-gray-300">{item.price}€ <span className="text-base font-medium">/mes</span></h2>

                                            <button className="transition-all w-full px-4 py-2 mt-6 tracking-wide text-white capitalize duration-300 transform bg-primary rounded-md hover:bg-primary focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80 hover:scale-105 ">
                                                Empezar ahora
                                            </button>
                                        </div>

                                        <hr className="border-box_border dark:border-box_border_dark" />

                                        <div className="p-6">
                                            <h1 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">What’s included:</h1>

                                            <div className="mt-8 space-y-4">

                                                {
                                                    item.features.map((feature, index) => {

                                                        if (feature.include == true) {
                                                            return (
                                                                <div className="flex items-center">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-primary" viewBox="0 0 20 20" fill="currentColor">
                                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                                    </svg>

                                                                    <span className="mx-4 text-gray-700 dark:text-gray-300">{feature.name}</span>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="flex items-center">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                                                        <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                                                                    </svg>

                                                                    <span className="mx-4 text-gray-700 dark:text-gray-300">{feature.name}</span>
                                                                </div>
                                                            )
                                                        }

                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='w-full p-5 border rounded-lg md:mx-4 border-box_border dark:border-box_border_dark bg-box dark:bg-box_dark'>
                <div className="sm:flex sm:items-center sm:justify-between sm:space-x-10">
                    <div>
                        <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                            Workspaces
                        </h3>
                        <p className="mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
                            Overview of all registered workspaces within your organization.
                        </p>
                    </div>
                </div>
                <Table className="mt-8">
                    <TableHead>
                    <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
                       {data.columns.map((row, index) => {
                            if (row != 'LimiteID') {
                                return (
                                    <TableHeaderCell className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        {row}
                                    </TableHeaderCell>
                                )
                            }
                        })}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.rows.map((row, index) => {
                                return (
                                    <TableRow className='hover:bg-body dark:bg-body_dark'>
                                        <TableCell className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                            {row.Tipo}
                                        </TableCell>
                                        {data.columns.map((row2, index) => {
                                            if (row2 != 'LimiteID' && row2 != 'Tipo') {
                                                return (
                                                    <TableHeaderCell className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                                        {row[index]}
                                                    </TableHeaderCell>
                                                )
                                            }
                                        })}
                                        <TableCell>{}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </section>
        </>
    )
}
