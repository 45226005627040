import React from 'react'

import manualUsuario from '../css/manualUsuario.css'

import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md'

import { TransaccionesIMG, TransaccionIMG, PresupuestoIMG, ProximosMovimientosIMG, GraficosIMG, PresupuestosIMG, HomePageIMG } from '../assets'

export default function ManualUsuario() {

    function goTo(id) {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <>
            <h1 className='h1 mb-[80px]'>Manual de Usuario</h1>

            <hr class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[60px] mb-[60px] dark:bg-[#020102]" />

            <section className='py-[50px] grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] mt-5 gap-[50px] no-print'>
                <div class="gridElement bg-box border border-box_border rounded-lg w-full">
                    <div className='w-full'>
                        <h6 className='text-left font-semibold text-xl'>Funcionalidades Principales</h6>
                        <ul className='flex flex-col gap-2 pt-2'>
                            <li onClick={() => goTo("func")} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                                <span>Funcionalidades Principales</span>
                                <p className='flex items-center gap-3'>
                                    <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                                </p>
                            </li>
                            <li onClick={() => goTo("func")} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                                <span>Componentes</span>
                                <p className='flex items-center gap-3'>
                                    <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="gridElement bg-box border border-box_border rounded-lg">
                    <div className='w-full'>
                        <h6 className='text-left font-semibold text-xl'>Transacciones</h6>
                        <ul className='flex flex-col gap-2 pt-2'>
                            <li onClick={() => goTo("transacciones")} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                                <span>Transacciones</span>
                                <p className='flex items-center gap-3'>
                                    <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                                </p>
                            </li>
                            <li onClick={() => goTo("transaccion")} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                                <span>Transaccion   </span>
                                <p className='flex items-center gap-3'>
                                    <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="gridElement bg-box border border-box_border rounded-lg">
                    <div className='w-full'>
                        <h6 className='text-left font-semibold text-xl'>Presupuestos</h6>
                        <ul className='flex flex-col gap-2 pt-2'>
                            <li onClick={() => goTo("presupuestos")} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                                <span>Presupuestos</span>
                                <p className='flex items-center gap-3'>
                                    <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                                </p>
                            </li>
                            <li onClick={() => goTo("presupuesto")} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                                <span>Presupuesto</span>
                                <p className='flex items-center gap-3'>
                                    <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="gridElement bg-box border border-box_border rounded-lg">
                    <div className='w-full'>
                        <h6 className='text-left font-semibold text-xl'>Otros</h6>
                        <ul className='flex flex-col gap-2 pt-2'>
                            <li onClick={() => goTo("otros")} className='text-sm flex justify-between hover:bg-body p-2 rounded-lg'>
                                <span>Otros</span>
                                <p className='flex items-center gap-3'>
                                    <span className='block md:hidden group-hover:block'><MdArrowOutward /></span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <span  id="func"></span>
            <section className='' style={{ "breakAfter": "page" }}>
                <h2 class="mb-4 text-2xl leading-6 font-semibold text-primary dark:text-primary" id="caracteristicas">Funcionalidades Principales</h2>
                <div class="flex md:flex-row-reverse flex-col-reverse" style={{ "breakAfter": "page" }}>
                    <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                        <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                        <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                        <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800 object-cover relative">
                            <div style={{ "backgroundImage": "url(" + HomePageIMG + ")" }} className='w-[272px] h-[572px] mt-[2px] bg-no-repeat bg-cover bg-center'></div>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[32px] left-[33px]'>1</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[32px] right-[33px]'>2</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[64px] left-[75px]'>3</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[95px] left-[48px]'>4</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[132px] left-[105px]'>5</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[255px] left-[100px]'>6</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[400px] left-[20px]'>7</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center bottom-[20px] left-[50px]'>8</span>
                        </div>
                    </div>
                    <div class="pt-[50px] md:pt-0 md:pl-[20px] w-full mb-[20px]">
                        <div class="mr-[20px] text-black dark:text-white">
                            <div class="mr-[20px] text-black dark:text-white">
                                <p class="text-justify pt-[20px] text-xl">Caracteristicas</p>
                                <div class="pt-[20px]">
                                    <ul class="pt-[10px] list-disc pl-[24px]">
                                        <li class="pt-[6px]">Categorías Personalizadas: Filtra tus transacciones por categorías como suscripciones, viajes y alimentación para una fácil visualización y organización.</li>
                                        <li class="pt-[6px]">Búsqueda Rápida: Encuentra cualquier transacción rápidamente con la función de búsqueda integrada.</li>
                                        <li class="pt-[6px]">Registro Detallado: Consulta tus transacciones con fechas específicas y detalles claros de cada operación.</li>
                                        <li class="pt-[6px]">Interfaz Amigable: Disfruta de una interfaz limpia y fácil de usar, optimizada para tu comodidad y eficiencia.</li>
                                    </ul>
                                </div>
                            </div>
                            <p id="componentes" class="text-justify pt-[30px] text-xl">Componentes en la pantalla</p>
                            <div class="pt-[20px]">
                                <ul class="pt-[10px] list-decimal pl-[24px]">
                                    <li class="pt-[6px]">Boton de Ajustes</li>
                                    <li class="pt-[6px]">Boton de Notificaciones</li>
                                    <li class="pt-[6px]">Tituo de la vista</li>
                                    <li class="pt-[6px]">Selecion de vista dentro del Home</li>
                                    <li class="pt-[6px]">Resumen de los ingresos y gastos del mes actual</li>
                                    <li class="pt-[6px]">Ultimas 3 transacciones</li>
                                    <li class="pt-[6px]">Resumen de las ultimas notificaciones</li>
                                    <li class="pt-[6px]">Barra de navegacion para cambiar entre vistas</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[60px] mb-[60px] dark:bg-[#020102]" />
            <section className='flex flex-col gap-[50px]'>
                <h2 class="mb-4 text-2xl leading-6 font-semibold text-primary dark:text-primary">Transacciones</h2>
                <div class="flex md:flex-row-reverse flex-col-reverse" style={{ "breakAfter": "page" }}>
                    <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                        <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                        <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                        <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800 object-cover relative">
                            <div style={{ "backgroundImage": "url(" + TransaccionesIMG + ")" }} className='w-[272px] h-[572px] mt-[2px] bg-no-repeat bg-cover bg-center'></div>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[100px] left-[230px]'>1</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[140px] left-[5px]'>2</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[170px] left-[5px]'>3</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[487px] left-[205px]'>4</span>
                        </div>
                    </div>
                    <div id="transacciones" class="pt-[50px] md:pt-0 md:pl-[20px] w-full mb-[20px]">
                        <h4 class="text-primary font-medium text-lg leading-7 w-full text-center">Pantalla Transacciones</h4>
                        <div class="mr-[20px] text-black dark:text-white">
                            <p class="text-justify pt-[20px]">Componentes en la pantalla</p>
                            <div class="pt-[20px]">
                                <ul class="pt-[10px] pl-[24px] list-decimal">
                                    <li class="pt-[6px]">Buscador: Busca las transacciones por su concepto</li>
                                    <li class="pt-[6px]">Selector: Filtra las transacciones por su categoria</li>
                                    <li class="pt-[6px]">Tabla: Muestra las transacciones</li>
                                    <li class="pt-[6px]">Crear: Crear una nueva transaccion</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col-reverse md:flex-row gap-2" style={{ "breakAfter": "page" }}>
                    <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                        <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                        <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                        <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800 object-cover relative">
                            <div style={{ "backgroundImage": "url(" + TransaccionIMG + ")" }} className='w-[272px] h-[572px] mt-[2px] bg-no-repeat bg-cover bg-center'></div>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[118px] left-[15px]'>1</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[163px] left-[15px]'>2</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[205px] left-[160px]'>3</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[250px] left-[160px]'>4</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[295px] left-[160px]'>5</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[340px] left-[160px]'>6</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[490px] left-[65px]'>7</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[487px] left-[205px]'>8</span>
                        </div>
                    </div>
                    <div id="transaccion" class="pt-[50px] md:pt-0 md:pl-[20px] w-full mb-5 md:mb-5">
                        <h4 class="text-primary font-medium text-lg leading-7 w-full text-center">Transaccion</h4>
                        <div class="mr-[20px] text-black dark:text-white">
                            <p class="text-justify pt-[20px]">Componentes en la pantalla</p>
                            <div class="pt-[20px]">
                                <ul class="pt-[10px] pl-[24px] list-decimal">
                                    <li class="pt-[6px]">Campo Editable: Puedes cambiar el importe de la transaccion</li>
                                    <li class="pt-[6px]">Campo Editable: Puedes cambiar el concepto de la transaccion</li>
                                    <li class="pt-[6px]">Puedes cambiar la fecha de la transaccion</li>
                                    <li class="pt-[6px]">Desplegable para cambiar la categoria de la transaccion</li>
                                    <li class="pt-[6px]">Desplegable para cambiar la empresa de la transaccion</li>
                                    <li class="pt-[6px]">Desplegable para cambiar el tipo de la transaccion</li>
                                    <li class="pt-[6px]">Boton de eliminar, debe mantenerse pulsado durante unos segundos para confirmar que se desea eliminar la transaccion</li>
                                    <li class="pt-[6px]">Boton de crear una nueva transaccion</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[60px] mb-[60px] dark:bg-[#020102]" />
            <section className='flex flex-col gap-[50px]'>
                <h2 class="mb-4 text-2xl leading-6 font-semibold text-primary dark:text-primary">Presupuestos</h2>
                <div class="flex md:flex-row-reverse flex-col-reverse" style={{ "breakAfter": "page" }}>
                    <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                        <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                        <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                        <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800 object-cover relative">
                            <div style={{ "backgroundImage": "url(" + PresupuestosIMG + ")" }} className='w-[272px] h-[572px] mt-[2px] bg-no-repeat bg-cover bg-center'></div>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[125px] left-[180px]'>1</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[185px] left-[20px]'>2</span>
                        </div>
                    </div>
                    <div class="pt-[50px] md:pt-0 md:pl-[20px] w-full mb-[20px]">
                        <h4 id="presupuestos" class="text-primary font-medium text-lg leading-7 w-full text-center">Pantalla Presupuestos</h4>
                        <div class="mr-[20px] text-black dark:text-white">
                            <p class="text-justify pt-[20px]">Componentes en la pantalla</p>
                            <div class="pt-[20px]">
                                <ul class="pt-[10px] pl-[24px] list-decimal">
                                    <li class="pt-[6px]">Tabla: Muestra los presupuestos</li>
                                    <li class="pt-[6px]">Boton de crear nuevos presupuesto</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col-reverse md:flex-row gap-2" style={{ "breakAfter": "page" }}>
                    <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                        <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                        <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                        <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800 object-cover relative">
                            <div style={{ "backgroundImage": "url(" + PresupuestoIMG + ")" }} className='w-[272px] h-[572px] mt-[2px] bg-no-repeat bg-cover bg-center'></div>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[33px] right-[33px]'>1</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[115px] left-[80px]'>2</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[280px] left-[125px]'>3</span>
                            <span className='absolute text-white text-xs bg-black rounded-full size-[20px] flex items-center justify-center top-[484px] left-[160px]'>4</span>
                        </div>
                    </div>
                    <div class="pt-[50px] md:pt-0 md:pl-[20px] w-full mb-5 md:mb-5">
                        <h4 id="presupuesto" class="text-primary font-medium text-lg leading-7 w-full text-center">Presupuesto</h4>
                        <div class="mr-[20px] text-black dark:text-white">
                            <p class="text-justify pt-[20px]">Componentes en la pantalla</p>
                            <div class="pt-[20px]">
                                <ul class="pt-[10px] pl-[24px] list-decimal">
                                    <li class="pt-[6px]">Boton de eliminar</li>
                                    <li class="pt-[6px]">Selector de importe maximo</li>
                                    <li class="pt-[6px]">Graficos</li>
                                    <li class="pt-[6px]">Selector para actualizar la categoria</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-[60px] mb-[60px] dark:bg-[#020102]" />
            <section id="otros">
                <h2 class="mb-4 text-2xl leading-6 font-semibold text-primary dark:text-primary">Otros</h2>
                <div class="flex md:flex-row-reverse flex-col-reverse" style={{ "breakAfter": "page" }}>
                    <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
                        <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                        <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                        <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                        <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800 object-cover relative">
                            <div style={{ "backgroundImage": "url(" + GraficosIMG + ")" }} className='w-[272px] h-[572px] mt-[2px] bg-no-repeat bg-cover bg-center'></div>
                        </div>
                    </div>
                    <div class="pt-[50px] md:pt-0 md:pl-[20px] w-full mb-[20px]">
                        <h4 class="text-primary font-medium text-lg leading-7 w-full text-center">Graficos</h4>
                        <div class="mr-[20px] text-black dark:text-white">
                            <p class="text-justify pt-[20px]">Descripcion de la pantalla</p>
                            <div class="pt-[20px] pr-5">
                                <article className='flex flex-col gap-5'>
                                    <p className='text-justify'>
                                        Se muestran un conjunto de graficos que representan los datos de las transacciones y presupuestos de la aplicacion MoneyTrack. Para ello se utilizan diferentes tipos de graficos como barras, lineas y circulares.
                                    </p>
                                    <p className='text-justify'>
                                        Los datos se actualizan en tiempo real y se pueden filtrar por fechas y categorias.
                                    </p>
                                    <p className='text-justify'>
                                        Los datos se obtiene de los registros de las transacciones. Ademas, para que los datos sean mas visuales, se recomienda que se utilicen las categorias personalizadas y empresas. Esto hara que haya mayor cantidad de datos y por ello estos sean mas claros y faciles de entender.
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
