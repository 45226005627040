import React from 'react'

import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer class="bg-box dark:bg-box_dark border-t border-box_border dark:border-box_border_dark no-print">
            <div class="mx-auto w-full max-w-screen-xl">
                <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Aplicacion</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <Link to={"/manualusuario"} class="hover:text-primary">Manual de Usuario</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Developer</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <Link to={"https://developer.moneytrack.es"} class="hover:text-primary">API</Link>
                            </li>
                            <li class="mb-4">
                                <Link to={"https://developer.moneytrack.es/manual"} class="hover:text-primary">Manual del Desarrollador</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <Link to={"/privacy"} class="hover:text-primary">Politica de Privacidad</Link>
                            </li>
                            <li class="mb-4">
                                <Link to={"/licensing"} class="hover:text-primary">Licencia</Link>
                            </li>
                            <li class="mb-4">
                                <Link to={"/condiction"} class="hover:text-primary">Terminos y Condiciones</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Descargar</h2>
                        <ul class="text-gray-500 dark:text-gray-400 font-medium">
                            <li class="mb-4">
                                <Link to={"https://www.apple.com/es/app-store/"} class="hover:text-primary">iOS</Link>
                            </li>
                            <li class="mb-4">
                                <Link to={"https://app.moneytrack.es"} class="hover:text-primary">Navegador</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="px-4 py-6 md:flex md:items-center md:justify-between">
                    <span class="text-sm text-gray-500 dark:text-gray-300 sm:text-center">© {new Date().getFullYear()} <Link to="https://moneytrack.es/">MoneyTrack</Link>. All Rights Reserved.</span>
                    <div class="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse"></div>
                </div>
            </div>
        </footer>
    )
}
