import { Routes, Route } from "react-router-dom";
import { Footer, Header, Price } from "./components";
import { Home, Ios, Navegador, PageNotFound, ManualUsuario } from "./pages";

function App() {
  return (
    <>
      <Header />
      <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-10 py-5 dark:text-white bg-body dark:bg-body_dark">
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/price" element={<Price />} />
          <Route path="/navegador" element={<Navegador />} />
          <Route path="/ios" element={<Ios />} />
          <Route path="/manualusuario" element={<ManualUsuario />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </main>  
      <Footer />
    </>
  );
}

export default App;
